export const config = {
    settings: {
        ENV_NAME: import.meta.env.VITE_ENV_NAME,
        API_URL: import.meta.env.VITE_API_URL,
        APP_URL: import.meta.env.VITE_APP_URL,
    },
    auth: {
        AUTH0_AUDIENCE: import.meta.env.VITE_AUTH0_AUDIENCE,
        AUTH0_REDIRECT_URL: import.meta.env.VITE_AUTH0_REDIRECT_URL,
        AUTH0_LOGOUT_URL: import.meta.env.VITE_AUTH0_LOGOUT_URL,
        AUTH0_DOMAIN: import.meta.env.VITE_AUTH0_DOMAIN,
        AUTH0_CLIENT_ID: import.meta.env.VITE_AUTH0_CLIENT_ID,
    },
}
