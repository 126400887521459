import { lazy, memo, Suspense } from "react"
import { Navigate, Route, Routes } from "react-router-dom"
import { AuthenticationGuard } from "@/components/AuthenticationGuard"
import { LoadingSpinner } from "@/components/LoadingSpinner"
import { AuthRoutes, PageRoutes } from "@/utils/constants"

// Lazy-load pages
// const HomePage = lazy(() => import("@/pages/HomePage"))
const OrdersPage = lazy(() => import("@/pages/OrdersPage"))
// const SettingsPage = lazy(() => import("@/pages/SettingsPage"))
// const AnalyticsPage = lazy(() => import("@/pages/AnalyticsPage"))
const AdminPage = lazy(() => import("@/pages/AdminPage"))
const SignupWithInvitePage = lazy(() => import("@/pages/SignupWithInvitePage"))

// Route mapping (protected routes)
// TODO: enable these routes when the pages are implemented
const routes = [
    // { path: PageRoutes.HOME, component: HomePage },
    { path: PageRoutes.ORDERS, component: OrdersPage },
    // { path: PageRoutes.SETTINGS, component: SettingsPage },
    // { path: PageRoutes.ANALYTICS, component: AnalyticsPage },
    { path: PageRoutes.ADMIN, component: AdminPage },
]

export const defaultRoute = PageRoutes.ADMIN

export const Router = memo(() => {
    return (
        // Use React.Suspense to lazy-load components, and show a loading message while loading
        <Suspense fallback={<LoadingSpinner />}>
            <Routes>
                {/* Public routes */}
                <Route path={AuthRoutes.LOGIN} element={<SignupWithInvitePage />} />
                <Route path={AuthRoutes.CALLBACK} element={<LoadingSpinner />} />
                <Route path={AuthRoutes.LOGOUT} element={<Navigate to={defaultRoute} replace />} />

                {/* Protected routes */}
                {routes.map(({ path, component: Component }) => (
                    <Route key={path} path={path} element={<AuthenticationGuard component={Component} />} />
                ))}

                {/* Catch-all route */}
                <Route path="*" element={<Navigate to={defaultRoute} replace />} />
            </Routes>
        </Suspense>
    )
})
