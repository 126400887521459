import React from "react"
import { NavLink } from "react-router-dom"
import cn from "classnames"
import { usePlatform } from "@/contexts/PlatformContext"

export type NavItem = {
    icon: React.ReactNode
    label: string
    route: string
}

interface NavigationItemProps {
    navItem: NavItem
}

export const NavigationItem = ({ navItem }: NavigationItemProps) => {
    const { isWlz } = usePlatform()

    return (
        <NavLink
            to={navItem.route}
            className={({ isActive }) =>
                cn(`flex w-full flex-col items-center justify-between p-2 text-background-default`, {
                    "font-semibold": isActive,
                    "text-black": isWlz,
                })
            }
        >
            {() => (
                <>
                    <div className="p-2">{navItem.icon}</div>
                    <div className="mt-1 text-xs">{navItem.label}</div>
                </>
            )}
        </NavLink>
    )
}
