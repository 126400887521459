import React, { createContext, useContext } from "react"
import { useQuery } from "@tanstack/react-query"
import { useAuthenticatedApi } from "@/services/apiClient"

interface MerchantContextType {
    merchantCode?: string
    isLoading: boolean
    error?: string
}

const MerchantContext = createContext<MerchantContextType | undefined>(undefined)

export const MerchantProvider = ({ children }: { children: React.ReactNode }) => {
    const { getMerchants } = useAuthenticatedApi()
    const {
        data: merchants,
        isLoading,
        error,
    } = useQuery({
        queryKey: ["merchants"],
        queryFn: getMerchants,
    })
    const merchantCode = merchants?.[0].merchant_code

    return <MerchantContext.Provider value={{ merchantCode, isLoading, error: error?.message }}>{children}</MerchantContext.Provider>
}

export const useMerchantContext = () => {
    const context = useContext(MerchantContext)
    if (context === undefined) {
        throw new Error("useMerchantContext must be used within a MerchantProvider")
    }
    return context
}
