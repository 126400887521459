// const originalWarn = console.warn
const originalError = console.error

// These are the messages that we want to suppress - this is a temporary solution until the underlying issues are fixed
const suppressedMessages = [
    "MUI: The elevation provided",
    "forwardRef render functions accept exactly two parameters: props and ref.",
    'The pseudo class ":first-child" is potentially unsafe when doing server-side rendering. Try changing it to ":first-of-type".',
    "Support for defaultProps will be removed from memo components in a future major release.",
    "Legacy context API has been detected within a strict-mode tree.",
    "findDOMNode is deprecated and will be removed in the next major release.",
    "is deprecated in StrictMode.",
]

const filterOutput = (originalFunction: Function, suppressedMessages: string[]) => {
    return function filterOutput(...args: any[]) {
        if (typeof args[0] === "string" && suppressedMessages.some((msg) => args[0].includes(msg))) {
            return
        }
        originalFunction.apply(console, args)
    }
}

// console.warn = filterOutput(originalWarn, suppressedMessages)
console.error = filterOutput(originalError, suppressedMessages)
