import React from "react"
import { BrowserRouter } from "react-router-dom"
import { createRoot } from "react-dom/client"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { App } from "@/App"
import { Auth0ProviderWithNavigate } from "@/contexts/Auth0ProviderWithNavigate"
import "@/styles/globals.css"
import "@/utils/suppressConsoleLogs"

const root = createRoot(document.getElementById("root") as Element)
const queryClient = new QueryClient()

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <QueryClientProvider client={queryClient}>
                <Auth0ProviderWithNavigate>
                    <App />
                </Auth0ProviderWithNavigate>
            </QueryClientProvider>
        </BrowserRouter>
    </React.StrictMode>,
)
