import { useAuth0 } from "@auth0/auth0-react"
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from "axios"
import { config } from "@/config"
import { ClientCredentials, Merchant, Order, OrdersFilters, PaginatedResponse, SalesChannel } from "@/services/types"

interface ErrorResponse {
    type: string
    status: number
    detail: string
    field_name: string | null
}

export class ApiError extends Error {
    constructor(
        message: string,
        public readonly response?: ErrorResponse,
        public readonly status?: number,
    ) {
        super(message)
        this.name = "ApiError"
    }
}

const axiosInstance: AxiosInstance = axios.create({
    baseURL: `${config.settings.API_URL}/api`,
    timeout: 10000,
    headers: {
        "Content-Type": "application/json",
    },
})

const makeAuthenticatedApiCall = async <T>(
    method: "GET" | "POST",
    url: string,
    getAccessTokenSilently: () => Promise<string>,
    config: Partial<AxiosRequestConfig> = {},
): Promise<T> => {
    try {
        const token = await getAccessTokenSilently()
        const requestConfig: AxiosRequestConfig = {
            ...config,
            method,
            url,
            headers: {
                ...config.headers,
                Authorization: `Bearer ${token}`,
            },
        }
        const response = await axiosInstance(requestConfig)
        return response.data
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const axiosError = error as AxiosError<ErrorResponse>
            const errorResponse = axiosError.response?.data
            throw new ApiError(
                errorResponse?.detail || axiosError.message || "An error occurred",
                errorResponse,
                axiosError.response?.status,
            )
        }
        throw new ApiError("An unexpected error occurred")
    }
}

type ClientCredentialResponse = { data: ClientCredentials[] }

export const useAuthenticatedApi = () => {
    const { getAccessTokenSilently } = useAuth0()

    return {
        getMerchants: async (): Promise<Merchant[]> => {
            const response = await makeAuthenticatedApiCall<PaginatedResponse<Merchant[]>>(
                "GET",
                `/internal/merchants`,
                getAccessTokenSilently,
            )
            return response.data
        },
        getClientCredentials: (merchantCode: string) => {
            return async (): Promise<ClientCredentials> => {
                const response = await makeAuthenticatedApiCall<ClientCredentialResponse>(
                    "GET",
                    `/internal/merchants/${merchantCode}/auth/client-credentials`,
                    getAccessTokenSilently,
                )
                return response.data[0]
            }
        },
        rotateClientSecret: (merchantCode: string, clientId: string) => {
            return async (): Promise<ClientCredentials> => {
                return await makeAuthenticatedApiCall<ClientCredentials>(
                    "POST",
                    `/internal/merchants/${merchantCode}/auth/client-credentials/${clientId}/rotate-secret`,
                    getAccessTokenSilently,
                )
            }
        },
        getSalesChannels: (merchantCode: string) => {
            return async (): Promise<SalesChannel[]> => {
                return await makeAuthenticatedApiCall<SalesChannel[]>(
                    "GET",
                    `/internal/merchants/${merchantCode}/sales-channels`,
                    getAccessTokenSilently,
                )
            }
        },
        getOrders: (merchantCode: string, filters: OrdersFilters) => {
            return async (): Promise<PaginatedResponse<Order[]>> => {
                const params = new URLSearchParams()

                Object.entries(filters).forEach(([key, value]) => {
                    if (value !== undefined && value !== null) {
                        if (Array.isArray(value)) {
                            value.forEach((v) => params.append(key, v.toString()))
                        } else {
                            params.append(key, value.toString())
                        }
                    }
                })

                const config: Partial<AxiosRequestConfig> = params.toString() ? { params } : {}

                return await makeAuthenticatedApiCall<PaginatedResponse<Order[]>>(
                    "GET",
                    `/internal/merchants/${merchantCode}/sales-orders`,
                    getAccessTokenSilently,
                    config,
                )
            }
        },
    }
}
