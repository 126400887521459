import React from "react"
import { withAuthenticationRequired } from "@auth0/auth0-react"
import { LoadingSpinner } from "@/components/LoadingSpinner"

type ComponentType<P = {}> = React.ComponentType<P>

interface AuthenticationGuardProps<P extends object = {}> {
    component: ComponentType<P>
    [key: string]: any
}

export const AuthenticationGuard = <P extends object = {}>({ component: Component, ...props }: AuthenticationGuardProps<P>) => {
    const AuthenticatedComponent = withAuthenticationRequired(Component as ComponentType<object>, {
        onRedirecting: () => <LoadingSpinner />,
    })

    return <AuthenticatedComponent {...props} />
}
