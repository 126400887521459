import { DesyThemeProvider } from "@tb/desy"
import { MerchantProvider } from "@/contexts/MerchantContext"
import { PlatformProvider } from "@/contexts/PlatformContext"
import { Layout } from "@/layouts"
import { Router } from "@/router"

export const App = () => {
    return (
        <PlatformProvider>
            <DesyThemeProvider>
                <MerchantProvider>
                    <Layout>
                        <Router />
                    </Layout>
                </MerchantProvider>
            </DesyThemeProvider>
        </PlatformProvider>
    )
}
