import { Link } from "react-router-dom"
import cn from "classnames"

interface LogoProps {
    logoUrl: string
    link?: string
    containerClassname?: string
}

export const Logo = ({ logoUrl, link, containerClassname }: LogoProps) => {
    const logoWrapperClasses = cn("flex items-center justify-center w-auto h-full")

    const LogoImage = <img src={logoUrl} alt="Logo" className="max-h-full max-w-full object-contain" />

    return (
        <div className={cn("h-16", containerClassname)}>
            {link ? (
                <Link to={link} className={logoWrapperClasses}>
                    {LogoImage}
                </Link>
            ) : (
                <div className={logoWrapperClasses}>{LogoImage}</div>
            )}
        </div>
    )
}
