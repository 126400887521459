import React from "react"
import { Loader as PaxLoader } from "@partner-tech/pax-ui-kit"

interface CenteredLoaderProps {
    label?: string
}

export const LoadingSpinner = ({ label }: CenteredLoaderProps) => {
    return (
        <div className="flex h-full justify-center">
            <PaxLoader loading={true} label={label} variant="indeterminate" className={"bg-primary-main"}>
                &nbsp;
            </PaxLoader>
        </div>
    )
}
