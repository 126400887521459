import { createContext, ReactNode, useContext, useMemo } from "react"

export enum Platform {
    MFI = "mfi",
    WLZ = "wlz",
}

export const getPlatform = (): Platform => {
    return import.meta.env.VITE_PLATFORM === Platform.WLZ ? Platform.WLZ : Platform.MFI
}

interface PlatformConfig {
    platform: Platform
    isMfi: boolean
    isWlz: boolean
    logo: {
        primary: string
        secondary: string
    }
}

const logos: Record<Platform, PlatformConfig["logo"]> = {
    [Platform.MFI]: {
        primary: "/mfi/tb-mfi-white-logo.png",
        secondary: "/mfi/tb-mfi-black-logo.png",
    },
    [Platform.WLZ]: {
        primary: "/wlz/android-chrome-192x192.png",
        secondary: "/wlz/android-chrome-192x192.png",
    },
}

const createPlatformConfig = (platform: Platform): PlatformConfig => ({
    platform,
    isMfi: platform === Platform.MFI,
    isWlz: platform === Platform.WLZ,
    logo: logos[platform],
})

const PlatformContext = createContext<PlatformConfig>(createPlatformConfig(Platform.MFI))

export const usePlatform = () => useContext(PlatformContext)

interface PlatformProviderProps {
    children: ReactNode
}

export const PlatformProvider = ({ children }: PlatformProviderProps) => {
    const platform = getPlatform()
    const value = useMemo(() => createPlatformConfig(platform), [platform])

    return <PlatformContext.Provider value={value}>{children}</PlatformContext.Provider>
}
