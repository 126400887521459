import cn from "classnames"
import { Logo } from "./Logo"
import { Navigation } from "./Navigation"
import { ProfileMenu } from "./ProfileMenu"
import { usePlatform } from "@/contexts/PlatformContext"
import { defaultRoute } from "@/router"

export const Sidebar = () => {
    const { isWlz, logo } = usePlatform()

    return (
        <aside
            className={cn(`flex h-full w-24 flex-col items-center justify-between bg-primary-dark py-6`, {
                "bg-semantic-ui-content": isWlz,
            })}
            data-testid={"sidebar"}
        >
            <Logo logoUrl={logo.primary} link={defaultRoute} />
            <Navigation />
            <ProfileMenu />
        </aside>
    )
}
