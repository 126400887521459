import { SelectOption } from "@/utils/types"

export const enum PageRoutes {
    // HOME = "/home",
    ORDERS = "/orders",
    // SETTINGS = "/settings",
    // ANALYTICS = "/analytics",
    ADMIN = "/admin",
}

export const enum AuthRoutes {
    LOGIN = "/login",
    CALLBACK = "/callback",
    LOGOUT = "/logout",
}

const anyOption: SelectOption = { value: "ALL", label: "ALL" }

const orderStatusOptions: SelectOption[] = [
    { value: "initial", label: "Initial" },
    { value: "reservation_requested", label: "Reservation Requested" },
    { value: "reserved", label: "Reserved" },
    { value: "approval_requested", label: "Approval Requested" },
    { value: "approved", label: "Approved" },
    { value: "shipped", label: "Shipped" },
    { value: "delivered", label: "Delivered" },
    { value: "returned", label: "Returned" },
    { value: "cancellation_requested", label: "Cancellation Requested" },
    { value: "cancelled", label: "Cancelled" },
]

const orderAgeOptions: SelectOption[] = [
    { value: "<24 HRS", label: "<24 HRS" },
    { value: ">24 HRS", label: ">24 HRS" },
    { value: ">48 HRS", label: ">48 HRS" },
]

const mcfOptions: SelectOption[] = [
    { value: "ZEOS", label: "ZEOS" },
    { value: "AYMCF", label: "AYMCF" },
]

export const rowsPerPageOptions: SelectOption[] = [
    { value: "10", label: "10" },
    { value: "20", label: "20" },
    { value: "30", label: "30" },
    { value: "40", label: "40" },
    { value: "50", label: "50" },
]

export const enum OrderFilters {
    SEARCH = "search",
    ORDER_STATUS = "orderStatus",
    ORDER_AGE = "orderAge",
    MARKETPLACE = "marketplace",
    DATE_RANGE = "dateRange",
    MCF = "mcf",
    SORT = "sort",
}

export const enum PaginationFilters {
    PAGE = "page",
    ROWS_PER_PAGE = "rowsPerPage",
}

export const enum SortField {
    CREATED = "created",
}

type OrderFiltersWithStaticOptions = Exclude<
    OrderFilters,
    OrderFilters.SEARCH | OrderFilters.MARKETPLACE | OrderFilters.DATE_RANGE | OrderFilters.SORT
>

export const orderFilterOptions: Record<OrderFiltersWithStaticOptions, SelectOption[]> = {
    [OrderFilters.ORDER_STATUS]: orderStatusOptions,
    [OrderFilters.ORDER_AGE]: [anyOption, ...orderAgeOptions],
    [OrderFilters.MCF]: [anyOption, ...mcfOptions],
}
