import { ShoppingBagEmptyIcon } from "@partner-tech/pax-icons"
import { NavigationItem, NavItem } from "./NavigationItem"

const iconSize = 24

const navItems: NavItem[] = [
    // TODO: enable these items when the pages are implemented
    // {
    //     label: "Home",
    //     route: "/home",
    //     icon: <HomeIcon size={iconSize} />,
    // },
    {
        label: "Orders",
        route: "/orders",
        icon: <ShoppingBagEmptyIcon size={iconSize} />,
    },
    // {
    //     label: "Settings",
    //     route: "/settings",
    //     icon: <EqualizerIcon size={iconSize} />,
    // },
    // {
    //     label: "Analytics",
    //     route: "/analytics",
    //     icon: <ThreeLayersIcon size={iconSize} />,
    // },
]

export const Navigation = () => {
    return (
        <div className={`mt-16 flex h-full w-full flex-col`}>
            <div className={`flex flex-col items-center gap-8`}>
                {navItems.map((navItem, index) => (
                    <NavigationItem key={index} navItem={navItem} />
                ))}
            </div>
        </div>
    )
}
