import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { useAuth0 } from "@auth0/auth0-react"
import { AvatarAIcon } from "@partner-tech/pax-icons"
import { Button, Menu, MenuItem } from "@tb/desy"
import cn from "classnames"
import { config } from "@/config"
import { usePlatform } from "@/contexts/PlatformContext"

interface ProfileMenuProps {
    title?: string
}

export const ProfileMenu = ({ title }: ProfileMenuProps) => {
    const [anchorEl, setAnchorEl] = useState<Element | null>(null)
    const navigate = useNavigate()
    const { logout } = useAuth0()
    const { isWlz } = usePlatform()

    const handleClick = (event: React.MouseEvent<Element>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleLogout = () => {
        logout({ logoutParams: { returnTo: config.auth.AUTH0_LOGOUT_URL } })
            .then(() => console.log("Logged out successfully!"))
            .catch((error) => {
                console.error("Failed to logout", error)
            })
        handleClose()
    }

    const handleCredentials = () => {
        navigate("/admin")
        handleClose()
    }

    return (
        <div className="mt-auto">
            <div
                className={cn(`flex w-full flex-col items-center justify-between text-background-default`, {
                    "text-black": isWlz,
                })}
            >
                <Button
                    variant={isWlz ? "outlined" : "text"}
                    onClick={handleClick}
                    id="profile-menu-button"
                    aria-label="Profile menu"
                    // redundant if component is fixed in TB.Desy
                    sx={{
                        "&.MuiButton-root.MuiButton-outlined": {
                            color: "black",
                            borderColor: "black",
                        },
                    }}
                >
                    <AvatarAIcon />
                </Button>
                {title && <div className={"mt-2 text-center text-sm"}>{title}</div>}
            </div>
            <Menu
                id="profile-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: 75,
                }}
                transformOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
            >
                <MenuItem onClick={handleCredentials}>API Client Credentials</MenuItem>
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
        </div>
    )
}
