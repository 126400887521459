import React from "react"
import { Sidebar } from "@/components/Sidebar"

interface LayoutProps {
    children: React.ReactNode
}

export const Layout = (props: LayoutProps) => {
    return (
        <main className="flex h-screen w-full overflow-hidden">
            <Sidebar />
            <main className="flex-1 overflow-y-auto overflow-x-hidden bg-canvas">{props.children}</main>
        </main>
    )
}
